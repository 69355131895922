/**
 * Returns true if the value is an Object
 * @param {*} value
 * @returns {Boolean}
 */
function isObject(value) {
    return typeof value === "object" && value !== null;
}

/**
 * Returns true if the Object is empty
 * @param {Object} object
 * @returns {Boolean}
 */
function isEmpty(object) {
    return !object || Object.keys(object).length === 0;
}

/**
 * Returns the Property as a boolean
 * @param {(Number|String)} property
 * @returns {Boolean}
 */
function isActive(property) {
    return Boolean(Number(property));
}

/**
 * Clones an Object
 * @param {Object} object
 * @returns {Object}
 */
function clone(object) {
    return JSON.parse(JSON.stringify(object));
}



/**
 * Restores an Item from Local Storage
 * @param {String} key
 * @param {String} defaultValue
 * @returns {String}
 */
function restoreItem(key, defaultValue = "") {
    try {
        const value = window.localStorage.getItem(key);
        return value || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

/**
 * Stores an Item in Local Storage
 * @param {String} key
 * @param {String} value
 * @returns {Boolean}
 */
function storeItem(key, value) {
    try {
        window.localStorage.setItem(key, value);
        return true;
    } catch (e) {
        return false;
    }
}



/**
 * Shortens the given Text if is too long
 * @param {String} text
 * @returns {String}
 */
function makeShort(text) {
    if (text.length > 100) {
        return text.substring(0, 100) + "...";
    }
    return text;
}

/**
 * Returns true if the given text has only Emojis
 * @param {String} text
 * @returns {Boolean}
 */
function isEmojiOnly(text) {
    const stringToTest = text.replace(/ /g, "");
    const emojiRegex = /^(?:(?:\p{RI}\p{RI}|\p{Emoji}(?:\p{Emoji_Modifier}|\u{FE0F}\u{20E3}?|[\u{E0020}-\u{E007E}]+\u{E007F})?(?:\u{200D}\p{Emoji}(?:\p{Emoji_Modifier}|\u{FE0F}\u{20E3}?|[\u{E0020}-\u{E007E}]+\u{E007F})?)*)|[\u{1f900}-\u{1f9ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}])+$/u;
    return emojiRegex.test(stringToTest) && Number.isNaN(Number(stringToTest));
}



/**
 * Returns the Language from the Navigator
 * @param {Object[]} languages
 * @param {String}   defaultLanguage
 * @returns {String}
 */
function getLanguage(languages, defaultLanguage) {
    let language = restoreItem("conversana-language");

    if (!language && navigator.languages) {
        language = (navigator.languages && navigator.languages[0]) || navigator.language || "es";
        if (language.includes("-")) {
            language = language.split("-")[0];
        }
        language = language.toLowerCase();
    }

    for (const item of languages) {
        if (item.key === language) {
            return language;
        }
    }
    return defaultLanguage || "es";
}

/**
 * Formats the Time
 * @param {Number} time
 * @returns {String}
 */
function formatTime(time) {
    const date     = new Date(time * 1000);
    const hours    = date.getHours();
    const minutes  = date.getMinutes();
    const minsText = minutes < 10 ? `0${minutes}` : minutes;
    return `${hours}:${minsText}`;
}

/**
 * Returns the Current Time
 * @returns {Number}
 */
function getCurrentTime() {
    return Math.floor(new Date().getTime() / 1000);
}

/**
 * Returns the Time as a String
 * @param {Number}  time
 * @param {Boolean} withHour
 * @returns {String}
 */
function getDayString(time, withHour) {
    const options = { month : "short", day : "numeric" };
    const date    = new Date(time * 1000);
    const now     = new Date();

    if (withHour) {
        options.hour   = "numeric";
        options.minute = "numeric";
    }
    if (now.getFullYear() !== date.getFullYear())  {
        options.year = "numeric";
    }

    // @ts-ignore
    return date.toLocaleString(undefined, options);
}

/**
 * Creates a Hash with the given Hash
 * @param {Number} length
 * @returns {String}
 */
function createHash(length) {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result  = "";
    let counter = 0;
    while (counter < length) {
        result  += characters.charAt(Math.floor(Math.random() * characters.length));
        counter += 1;
    }
    return result;
}



/**
 * Parses the Messages
 * @param {Object[]} messages
 * @param {Boolean}  showUnread
 * @returns {{items: Object[], unreadIDs: Number[]}}
 */
function parseMessages(messages, showUnread) {
    const list      = messages.sort((a, b) => a.createdTime - b.createdTime);
    const items     = [];
    const unreadIDs = [];

    let lastDay     = "";
    let lastType    = "";
    let lastID      = 0;
    let index       = -1;

    for (const item of list) {
        const { messageID, createdTime, credentialID, contactID, fromFlow, userName, userFirstName, isRead } = item;
        const isMine       = !credentialID && !fromFlow;
        const thisDay      = new Date(createdTime * 1000).toDateString();
        const thisType     = isMine ? "mine" : "yours";
        const thisID       = fromFlow ? -1 : (credentialID ? credentialID : contactID);
        const isNewDay     = thisDay !== lastDay;
        const isNewUser    = thisType !== lastType || thisID !== lastID;
        const newMessage   = !unreadIDs.length && !isMine && !isRead;
        const isNewMessage = showUnread && newMessage;

        if (isNewDay || isNewUser || isNewMessage) {
            index       += 1;
            items[index] = {
                isMine, userName, userFirstName,
                dayName : isNewDay ? getDayString(createdTime, false) : "",
                unread  : newMessage,
                list    : [],
            };
        }
        if (!isMine && !isRead) {
            unreadIDs.push(messageID);
        }
        items[index].list.push(item);

        lastDay  = thisDay;
        lastType = thisType;
        lastID   = thisID;
    }
    return { items, unreadIDs };
}

/**
 * Adds new Messages to the old ones
 * @param {Object[]} oldMessages
 * @param {Object[]} newMessages
 * @returns {Object[]}
 */
function addMessages(oldMessages, newMessages) {
    const messages = oldMessages.slice(0);
    if (newMessages && newMessages.length) {
        for (const newElem of newMessages) {
            let found = false;
            for (const [ index, oldElem ] of messages.entries()) {
                if (newElem.messageID === oldElem.messageID) {
                    messages[index] = newElem;
                    found = true;
                    break;
                }
            }
            if (!found) {
                messages.push(newElem);
            }
        }
    }
    return messages;
}




// The public API
export default {
    isObject,
    isEmpty,
    isActive,
    clone,

    restoreItem,
    storeItem,

    makeShort,
    isEmojiOnly,
    getLanguage,
    formatTime,
    getCurrentTime,
    getDayString,
    createHash,

    parseMessages,
    addMessages,
};
